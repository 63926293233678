/* eslint-disable camelcase */
import { Component } from '@angular/core';
import { ApplicationService } from '@app/services/application.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '@env/environment';

@Component({
  selector: 'app-email-test',
  templateUrl: './email-test.component.html',
  styleUrls: ['./email-test.component.css']
})
export class EmailTestComponent {
  email: string;
  franchiseeCode: string;
  requestData = {
    method        : 'POST',
    url           :  environment.INTCBK_URL + '/send-email',
    authorization : 'Basic ' + btoa(environment.ONEC_SERVICE_USER + ':' + environment.ONEC_SECRET_KEY),
    'Content-Type'  : 'application/json',
    body          : {
      to: [''],
      from: '',
      bcc: ['rohit@tanglesolutions.com'],
      cc: ['rohit.tanglesolutions@gmail.com'],
      custom_args: {
          to: [''],
          from: '',
          subject: 'OWD-780: Compliance: TEST Email'
      },
      subject: 'OWD-780: Compliance: TEST Email',
      franchiseeCode: '000',
      templateName: 'DECO_TEST_EMAIL',
      templateData: {
        isUnsubscribeLink         : false,
        PhysicalAddressVisibility : true,
        PhysicalAddress           : false,
        SocialLinksVisibility     : 'table',
        demoText                  : 'OWD-780: Compliance: TEST Email Content.'
      }
    }
  };
  responseData: any;
  franchiseList: any = [];

  constructor(
    private apiService: ApplicationService,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.getFranchiseList();
  }

  getFranchiseList(): void {
    this.apiService.getFranchiseList().then((response: any) => {
      if (response.success) {
        this.franchiseList = response.franchiseList;
      }
    }).catch(error => {
      console.log('DEBUG: -> EmailTestComponent -> this.apiService.getFranchiseList -> error', error);
    });
  }

  onSendEmail(): void {
    if (this.email) {
      this.requestData.body.to = [this.email];
      this.requestData.body.from = this.email;
      this.requestData.body.franchiseeCode = this.franchiseeCode ? this.franchiseeCode : '000';
      this.requestData.body.custom_args.to = [this.email];
      this.requestData.body.custom_args.from = this.email;
      this.ngxLoader.start();
      this.apiService.sendTestEmail(this.requestData).then(response => {
        this.ngxLoader.stop();
        this.responseData = response;
      }).catch(error => {
        console.log('DEBUG: -> EmailTestComponent -> this.apiService.sendTestEmail -> error', error);
      });
    } else {
      window.alert('Invalid email!');
    }
  }
}
